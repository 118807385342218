.modal {
  .modal-content {
    padding: $gap;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .modal-title {
    @include h2-semibold;

    @include media-breakpoint-down(sm) {
      position: sticky;
      top: 0;
      background: $white-color;
    }
  }

  .modal-body {
    padding: 0;

    @include media-breakpoint-down(sm) {
      padding: 0 $gap-half $gap;
    }
  }

  .btn-close:focus {
    box-shadow: 0 0 0 0.25rem #{$secondary-100};
  }

  .modal-action-row {
    display: flex;
    flex-direction: row;
    gap: $gap-half;
    margin-top: $gap;
    justify-content: center;

    .btn {
      padding-inline: $gap;
      min-width: 120px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .btn-primary {
        order: 1;
      }

      .btn-outline-primary {
        order: 2;
      }
    }
  }
}


@include media-breakpoint-down(sm) {
  .modal-dialog {
    --bs-modal-margin: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .modal-content {
    --bs-modal-border-radius: 0;
    --bs-modal-border-width: 0;
    border: 0 !important;
  }

  .modal-body {
    max-height: calc(100dvh - 40px);
    overflow-y: auto;
  }

  .modal-backdrop {
    height: 100svh;
  }
}
