/* fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animated.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

/* fadeInUp */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUp {
  animation: fadeInUp 1s ease-in-out;
}

/* fadeInDown */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDown {
  animation: fadeInDown 1s ease-in-out;
}

/* fadeInRight */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRight {
  animation: fadeInRight 1s ease-in-out;
}

/* fadeInLeft */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
    transform: translateY(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateY(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translateY(100%, 0, 0);
  }
}

// Classes for each fade-out effect
.animated.fadeOut {
  animation: fadeOut 1s ease forwards;
}

.animated.fadeOutDown {
  animation: fadeOutDown 1s ease forwards;
}

.animated.fadeOutUp {
  animation: fadeOutUp 1s ease forwards;
}

.animated.fadeOutLeft {
  animation: fadeOutLeft 1s ease forwards;
}

.animated.fadeOutRight {
  animation: fadeOutRight 1s ease forwards;
}

.animate-delay-2 {
  animation-delay: 2s;
}

@for $i from 1 through 10 {
  .animated.animate-delay-#{$i} {
    animation-delay: #{$i}s !important;
  }
}

@for $i from 1 through 10 {
  .animated.animate-duration-#{$i} {
    animation-duration: #{$i}s !important;
  }
}

// Shop banner animations:
@media (prefers-reduced-motion: reduce) {

  .fadeOutUp_ShopBanner,
  .fadeInDown_ShopBanner {
    animation: none;
    transform: none;
    /* Evita cambios visuales abruptos */
    opacity: 1;
    /* Asegura visibilidad en su estado final */
  }
}

@keyframes fadeOutUp_ShopBanner {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  
  100% {
    opacity: 0;
    transform: translateY(-100%);
    display: none;
    height: 0;
  }
}

.fadeOutUp_ShopBanner {
  animation: fadeOutUp_ShopBanner 0.2s ease forwards;
  overflow: hidden;
  // height: 0;
  // transition: height 1s ease;
}

@keyframes fadeInDown_ShopBanner {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown_ShopBanner {
  animation: fadeInDown_ShopBanner .2s ease-in-out;
}