$head-height: 39px;

.product-card-grid {
  padding-block: $gap-half;

  @include media-breakpoint-down(sm) {
    &.padding-at-bottom-sm {
      padding-bottom: 125px;
    }
  }

  &__item {
    width: 100%;
    margin-bottom: $gap;
    height: calc(100% - #{$gap});

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }

    &__placeholder {
      height: 500px;

      @include media-breakpoint-down(lg) {
        height: 350px;
      }
    }
  }

  &.row {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 8px;
    }
  }
}

.product-card {
  position: relative;
  // $border-radius: 4px;
  // border-radius: $border-radius;
  // border: 1px solid $gray-100;
  background: $pure-white;
  padding: 16px;
  min-height: 100%;

  @include media-breakpoint-down(sm) {
    padding: 8px;
  }

  &--subscription {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 30px;
      height: calc(100% - #{$mb*2});
      margin-block: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &--check-address {
    position: relative;
    height: 100%;

    .close-mark {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .product-card__body {
      $mb: 20px;
      height: calc(100% - $mb);
      margin-top: $mb;

      section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &--summary {
    border: 1px solid $form-disable-bg;
    padding: 16px;
    border-radius: 8px;
  }

  &__pre-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0;
    right: 10px;
    top: 10px;
    gap: 8px;
    z-index: 1;

    span {
      cursor: pointer;
    }

    span:hover {
      opacity: 0.5;
    }
  }

  /*product-card-head*/
  &__head {
    height: $head-height;
    margin-bottom: 8px;

    a {
      text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
      height: 33px;
      margin-bottom: 3px;
    }
  }

  &__title {
    @include h5-bold;
    @include is-head-font;
    color: $secondary-800;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @include media-breakpoint-down(sm) {
      font-size: $size-6;
    }

    &.show-all {
      &:hover {
        height: 5ch !important;
        text-overflow: initial !important;
        white-space: normal !important; // white-space normal en lugar de text-wrap
      }
    }
  }

  &__subtitle {
    @include h6-regular;
    color: $secondary-700;
    margin-bottom: 0;
    line-height: 1;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      font-size: $size-7;
    }
  }

  /*product-card-body*/
  .product-card__img-wrap {
    width: 100%;
    margin-bottom: $gap-half;
    position: relative;

    .info-badge {
      position: absolute;
      bottom: 17%;
      left: 50%;
      transform: translate(-50%, 23%);

      button {
        @include h7-semibold;
        background-color: white;
        border: 1px solid $secondary-color;
        color: $secondary-color;
        border-radius: 16px;
        padding: 5px 10px;
        text-align: center;
      }

      button:hover {
        background-color: $secondary-color;
        color: white;
      }
    }

    .sale-badge {
      width: 25px;
      height: 25px;
      position: absolute;
      left: 20px;
      top: 0px;
      display: grid;
      place-content: center;
      background-color: $danger-500;
      color: $danger-contrast-500;
      font-size: $size-8;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;

        span {
          font-size: $size-7 !important;
        }
      }

      span {
        font-size: var(--font-size-5);
      }
    }

    .sale-badge::after {
      content: " ";
      width: 0px;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $danger-500;
      position: absolute;
      bottom: -12px;

      @include media-breakpoint-down(sm) {
        border-left-width: 10px;
        border-right-width: 11px;
        bottom: -8px;
        border-top-width: 9px;
      }
    }

    // .sale-badge::before {
    //   content: " ";
    //   width: 0px;
    //   height: 0;
    //   position: absolute;
    //   bottom: 24px;
    //   left: -4px;
    //   width: 29px;
    //   height: 3px;
    //   background-color: $danger-500;
    //   border-top-right-radius: 50px;
    //   border-top-left-radius: 50px;

    //   @include media-breakpoint-down(sm) {
    //     bottom: 20px;
    //     left: -3px;
    //     width: 23px;
    //     height: 3px;
    //   }
    // }

    .in-order {
      background-color: #0000004f;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 0;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        @include is-head-font;
        color: white;
        font-size: 1.8em;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
          font-size: 1.2em;
        }
      }
    }
  }

  &__img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3 / 1.7;
    border-radius: 15px;
  }

  &__meta {
    height: 110px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 0px;

    mat-form-field {
      margin: 0px !important;
    }

    .product-card__info {
      @include h8-regular;
      color: $secondary-700;
      height: 15px;
      margin-bottom: 0;
    }
  }

  &__meta--item {
    margin-bottom: 0px;
    display: flex;
    width: 100%;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;

    text &.is-col-2 {
      //display: grid;
      //grid-template-columns: repeat(2, 50%);
      //@include media-breakpoint-down(lg) {
      //  grid-template-columns: 70% 30%;
      //}

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .quantity {
      max-width: 75px;

      @include media-breakpoint-down(xl) {
        max-width: 66px;
      }

      @include media-breakpoint-down(lg) {
        max-width: 65px;

        .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
          padding-inline: 5px;
        }
      }

      @include media-breakpoint-down(sm) {
        max-width: 30px;

        mat-form-field {
          margin: 0px;
        }
      }
    }

    .product-card__price {
      @include h4-bold;
      color: $secondary-800;
      margin-bottom: 0;
      line-height: 1;
      font-variant-numeric: tabular-nums;

      @include media-breakpoint-down(sm) {
        font-size: $size-6;
      }
    }

    .product-card__price--line-through {
      font-variant-numeric: tabular-nums;

      @include media-breakpoint-down(sm) {
        // font-size: calc($size-8 *.8);
        font-size: $size-8;
      }
    }
  }

  &__action {
    margin-bottom: 12px;
    margin-top: 10px;

    @include media-breakpoint-down(sm) {
      margin-top: 0px;
    }

    .btn {
      width: auto;
      height: 36px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      flex-grow: 1;
      font-size: $size-6;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .action-col-2 {
      display: flex;
      gap: $gap-half;

      .btn {
        white-space: nowrap;
      }

      @include media-breakpoint-down(md) {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
    }
  }

  &__foot {
    display: flex;

    &.is-col-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;

    }
  }

  .product-card__tags {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $gap-half;
    height: 20px;
    overflow: auto;
    white-space: nowrap;
    overflow-y: hidden;

    @include media-breakpoint-down(sm) {
      justify-content: start;
    }

    div {
      @include h7-semibold;
      background-color: #E6F9FF;
      color: #023A4C;
      border-radius: 30px;
      padding: 2px 10px;
      display: inline-block;
    }
  }

  .product-card__tags::-webkit-scrollbar {
    height: 0px;
  }

  .custom-checkbox {
    $height: 18px;
    height: $height;
    width: $height;
    position: relative;
    cursor: pointer;

    .fa-regular {
      color: gray;
    }

    .fa-heart {
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s ease-in-out;
    }

    .fa-solid {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .fa-solid {
      opacity: 0.5;
    }

    input[type="checkbox"]:checked~.fa-solid {
      opacity: 1;
    }
  }




  //used for rounded-selector arrow position
  .mat-mdc-select-empty,
  .mat-form-field-hide-placeholder,
  .mdc-floating-label--float-above+.mat-mdc-select {
    .mat-mdc-select-arrow svg {
      top: 10px;
    }
  }
}


.keen-slider__slide {
  max-width: 250px;
  min-width: 250px;

  app-product-card {
    max-width: 250px;
    min-width: 250px;

    .product-card__tags {
      display: none !important;
    }
  }
}