@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$head-font: "Fraunces", sans-serif;
$body-font: "Inter", sans-serif;
$tertiary-font: "Poppins", sans-serif;

$base-font-size: var(--font-size-6);


:root {
  --font-size-1: 44px;
  --font-size-2: 32px;
  --font-size-3: 26px;
  --font-size-4: 20px;
  --font-size-5: 18px;
  --font-size-6: 16px;
  --font-size-7: 14px;
  --font-size-8: 12px; //10px;
}

body {
  --bs-body-font-family: $body-font;
}


$size-1: var(--font-size-1);
$size-2: var(--font-size-2);
$size-3: var(--font-size-3);
$size-4: var(--font-size-4);
$size-5: var(--font-size-5);
$size-6: var(--font-size-6);
$size-7: var(--font-size-7);
$size-8: var(--font-size-8);


$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-semibold: 500 !default;
$weight-bold: 700 !default;

html,
body {
  font-size: $base-font-size;
  font-family: $body-font;
}

@mixin fontGenerator($weight,
  $size,
  $line-height,
  $letter-spacing: normal,
  $family: $body-font) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  font-family: $family, sans-serif;
}

@mixin h1-regular {
  @include fontGenerator($weight-normal, $size-1, normal, normal, $body-font);
}

@mixin h1-semibold {
  @include fontGenerator($weight-semibold, $size-1, normal, normal, $body-font);
}

@mixin h1-bold {
  @include fontGenerator($weight-bold, $size-1, normal, normal, $body-font);
}


@mixin h2-regular {
  @include fontGenerator($weight-normal, $size-2, 1.3, normal, $body-font);
}

@mixin h2-semibold {
  @include fontGenerator($weight-semibold, $size-2, 1.3, normal, $body-font);
}

@mixin h2-bold {
  @include fontGenerator($weight-bold, $size-2, 1.3, normal, $body-font);
}


@mixin h3-regular {
  @include fontGenerator($weight-normal, $size-3, 1.25, normal, $body-font);
}

@mixin h3-semibold {
  @include fontGenerator($weight-semibold, $size-3, 1.25, normal, $body-font);
}

@mixin h3-bold {
  @include fontGenerator($weight-bold, $size-3, 1.25, normal, $body-font);
}


@mixin h4-regular {
  @include fontGenerator($weight-normal, $size-4, 1.35, normal, $body-font);
}

@mixin h4-semibold {
  @include fontGenerator($weight-semibold, $size-4, 1.35, normal, $body-font);
}

@mixin h4-bold {
  @include fontGenerator($weight-bold, $size-4, 1.35, normal, $body-font);
}

@mixin h5-regular {
  @include fontGenerator($weight-normal, $size-5, 1.35, normal, $body-font);
}

@mixin h5-semibold {
  @include fontGenerator($weight-semibold, $size-5, 1.35, normal, $body-font);
}

@mixin h5-bold {
  @include fontGenerator($weight-bold, $size-5, 1.35, normal, $body-font);
}


/*h6 is the base*/
@mixin h6-regular {
  @include fontGenerator($weight-normal, $size-6, 1.4, normal, $body-font);
}

@mixin h6-semibold {
  @include fontGenerator($weight-semibold, $size-6, 1.4, normal, $body-font);
}

@mixin h6-bold {
  @include fontGenerator($weight-bold, $size-6, 1.4, normal, $body-font);
}

@mixin h7-regular {
  @include fontGenerator($weight-normal, $size-7, 1.4, normal, $body-font);
}

@mixin h7-semibold {
  @include fontGenerator($weight-semibold, $size-7, 1.4, normal, $body-font);
}

@mixin h7-bold {
  @include fontGenerator($weight-bold, $size-7, 1.4, normal, $body-font);
}

@mixin h8-regular {
  @include fontGenerator($weight-normal, $size-8, 1.4, normal, $body-font);
}

@mixin h8-semibold {
  @include fontGenerator($weight-semibold, $size-8, 1.4, normal, $body-font);
}

@mixin h8-bold {
  @include fontGenerator($weight-bold, $size-8, 1.4, normal, $body-font);
}




.h1-regular {
  @include h1-regular;
  line-height: 1.2;
}

.h1-semibold {
  @include h1-semibold;
  line-height: 1.2;
}

.h1-bold {
  @include h1-bold;
  line-height: 1.2;
}

.h2-regular {
  @include h2-regular;
}

.h2-semibold {
  @include h2-semibold;
}

.h2-bold {
  @include h2-bold;
}

.h3-regular {
  @include h3-regular;
}

.h3-semibold {
  @include h3-semibold;
}

.h3-bold {
  @include h3-bold;
}

.h4-regular {
  @include h4-regular;
}

.h4-semibold {
  @include h4-semibold;
}

.h4-bold {
  @include h4-bold;
}


.h5-regular {
  @include h5-regular;
}

.h5-semibold {
  @include h5-semibold;
}

.h5-bold {
  @include h5-bold;
}

.h6-regular {
  @include h6-regular;
}

.h6-semibold {
  @include h6-semibold;
}

.h6-bold {
  @include h6-bold;
}

.h7-regular {
  @include h7-regular;
}

.h7-semibold {
  @include h7-semibold;
}

.h7-bold {
  @include h7-bold;
}

.h8-regular {
  @include h8-regular;
}

.h8-semibold {
  @include h8-semibold;
}

.h8-bold {
  @include h8-bold;
}

@mixin is-head-font {
  font-family: $head-font;
}

.is-head-font {
  font-family: $head-font;
}

@mixin is-tertiary-font {
  font-family: $tertiary-font;
}

.is-tertiary-font {
  font-family: $tertiary-font;
}

@mixin line-clamp-1 {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: calc(1em * 1.2);
}

@mixin line-clamp-2 {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: calc(2em * 1.2);
}

@mixin line-clamp-3 {
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: calc(3em * 1.2);
}