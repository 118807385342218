$nav-category-color: $black-color;
$nav-main-color: $black-color;

.top-header {
  @include h7-regular();
  height: $header-height;

  @include media-breakpoint-down(lg) {
    height: $header-height-mobile;
    border-bottom: 1px solid $gray-100;

    &.isShopNavigationDisplayed {
      height: calc($header-height-mobile + $shop-navigation-height);
    }
  }

  .navbar-toggler {
    $height: 36px;
    height: auto;
    width: auto;
    background: transparent;
    border-radius: 0;
    border: 0; //1px solid $gray-100;
    display: grid;
    place-content: center;
    margin: 0;
    padding: 0;

    &>span {
      font-size: 28px;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:focus {
      box-shadow: none;
      background: $secondary-100;
    }
  }

  .search-toggler {
    background: none;
    border: none;
    place-content: center;
  }

  .navbar {
    height: calc($header-height - $sub-nav-height);
  }

  .nav-category {
    height: $sub-nav-height;

    @include media-breakpoint-down(lg) {
      height: $sub-nav-height-mobile;
      padding-inline: $gap;

      .mat-mdc-form-field {
        margin-bottom: inherit;
      }
    }

    @include media-breakpoint-down(sm) {
      padding-inline: $gap-half;
    }
  }
}

.top-header--hidden-sub-nav {
  @extend .top-header;
  height: calc($header-height - $sub-nav-height);
  border-bottom: 1px solid $gray-100;

  @include media-breakpoint-down(lg) {
    height: calc($header-height-mobile - $sub-nav-height-mobile);
  }
}

.navbar-brand {
  margin: 0;
  padding: 0;

  img {
    height: 35px;
  }
}

.navbar-nav {
  .btn {
    white-space: nowrap;
  }

  .nav-item--icon {
    .nav-link {
      $height: 34px;
      height: $height;
      width: $height;
      line-height: 1;
      border-radius: $height;
      display: grid;
      place-content: center;
      color: $nav-main-color;
      margin-inline: 2px;

      &:hover {
        color: lighten($nav-main-color, 10%);
      }

      &.active-link {
        color: darken($nav-main-color, 5%);
      }
    }
  }
}


.nav-main {
  padding-inline: $gap;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(sm) {
    padding-inline: $gap-half;
  }

  &.navbar-nav {
    gap: $gap;

    .nav-item {
      .nav-link {
        @include h7-regular();
        text-transform: capitalize;
      }
    }

    .btn {
      white-space: nowrap;
    }
  }

  .nav-link {
    color: $nav-main-color;
  }
}

.nav-category {
  padding-block: 0;

  .nav-content {
    @extend .nav-main;
    display: flex;
    justify-content: space-around;
    padding: 0 !important;
  }

  .navbar-nav {
    gap: .5rem;
    justify-content: space-between;
    margin-left: -6px;

    .nav-item {
      .nav-link {
        @include h7-regular();
        color: $nav-category-color;
        text-transform: capitalize;
      }
    }
  }

  &.nav-category--desktop {
    display: block;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .top-header--hidden-sub-nav & {
      display: none;
    }
  }

  &.nav-category--mobile {
    display: none;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .top-header--hidden-sub-nav & {
      display: none;
    }

    .categories-container {
      display: flex;
      flex-direction: row;

      .searchbar {
        margin-bottom: 0;
      }
    }

    mat-form-field {
      width: calc(100% - 45px);
    }

    app-search-bar {
      width: 100%;
    }


    .category-search--mobile {
      width: calc(100% - 60px);
      // height: $sub-nav-height-mobile;
      height: $header-primary-height;
      display: flex;
      align-items: center;
    }

    .category-menu {
      width: 45px;

      &__icon {
        background-color: transparent;
        border: none;
        color: $primary-900;
      }
    }

    .btn-filter-mobile {
      .icon-sort {
        display: none;
      }

      .icon-close {
        display: block;
      }

      &.is-open {
        .icon-sort {
          display: block;
        }

        .icon-close {
          display: none;
        }
      }
    }

    /*category search bar*/
    ::ng-deep .mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper {
      height: 42px !important;
    }

    ::ng-deep .mat-mdc-form-field.mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix {
      --mat-form-field-container-height: 42px;
      --mat-form-field-filled-with-label-container-padding-top: 16px;
    }

    ::ng-deep .mdc-text-field .mdc-floating-label mat-label {
      font-size: $size-8;
    }

  }

}

.top-header {
  .dropdown-menu.dropdown-menu-end {
    z-index: 1046;
  }

  .dropdown-header {
    --bs-dropdown-header-color: #{$primary-900};
    @include h7-regular;
  }

  .dropdown-item {
    --bs-dropdown-link-color: #{$primary-900};
    @include h6-regular;
    text-transform: capitalize;

    &:hover,
    &:focus {
      --bs-dropdown-link-hover-color: #{$primary-900};
      --bs-dropdown-link-hover-bg: #{$primary-100};
    }

    &.active,
    &:active {
      --bs-dropdown-link-active-color: #ffffff;
      --bs-dropdown-link-active-bg: #{$primary-900};
    }
  }

  .rotate-icon {
    transform: rotate(180deg);
  }

  .vw-75 {
    width: 70vw;
  }

  .categories-container {
    height: calc($sub-nav-height); //defualt 40px;

    @include media-breakpoint-down(lg) {
      height: calc($sub-nav-height - 4px);
    }
  }

  .nav-category--icon {
    height: calc($sub-nav-height - 4px);
    width: calc($sub-nav-height);
    padding: 0;
    display: grid;
    place-content: center;
  }

  .dropdown-menu {
    $icon-width: 24px;

    .dropdown-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      @include h5-semibold;
      color: $nav-main-color;
      --bs-dropdown-header-padding-x: #{$gap-half};
      --bs-dropdown-header-padding-y: 5px;
      padding-bottom: 0;

      .icon {
        width: $icon-width;
        align-items: center;
        display: grid;
        place-content: center;
      }
    }

    .dropdown-item {
      @include h6-regular;
      padding-left: calc($icon-width + 30px);

      &:hover,
      &:focus {
        --bs-dropdown-link-hover-color: #{$nav-main-color};
        --bs-dropdown-link-hover-bg: #{rgba($nav-main-color, .1)};
      }

      &.active,
      &:active {
        --bs-dropdown-link-active-color: #ffffff;
        --bs-dropdown-link-active-bg: #{$nav-main-color};
      }
    }

    .nav-link.with-action {
      margin-left: 0;
      display: flex;
      gap: calc($icon-width - 10px);
      align-items: center;
    }
  }

  .nav-category--mobile .nav-category--icon {
    display: none !important;
  }


}


.offcanvas.mobile-menu {
  --bs-offcanvas-padding-y: 16px;
  --bs-offcanvas-padding-x: 16px;
  margin-top: $header-primary-height;
  margin-bottom: $mobile-footer-nav-height;
  width: max-content;
  min-width: 200px;

  &.hidden-mobile-footer {
    margin-bottom: 0px;
  }

  .nav-link {
    @include h6-semibold;
    color: $nav-main-color;
    --bs-nav-link-padding-y: #{$gap-quater};
  }

  .sub-menu {
    list-style: none;
    margin: 0;
    margin-left: 10px;
    padding: 0;

    .nav-link {
      @include h7-regular;
    }
  }

  .mobile-menu__sub {
    $icon-width: 24px;

    .mobile-menu__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-block: 8px 0;
      @include h6-semibold;
      color: $nav-main-color;

      .icon {
        width: $icon-width;
        align-items: center;
        display: grid;
        place-content: center;
      }
    }

    .nav-link {
      @include h7-regular;
      margin-left: calc($icon-width + 8px);
      --bs-nav-link-padding-y: 6px;

      &.with-action {
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.layout-static {
  .offcanvas.mobile-menu {
    margin-bottom: 0;
  }
}

.has-sub-menu {
  position: relative;

  &:hover {
    .sub-menu {
      display: block;
    }
  }

  .sub-menu {
    $border-radius: 5px;
    width: 220px;
    position: absolute;
    background: $pure-white;
    list-style: none;
    z-index: 99;
    padding: 0;
    margin: 0;
    border-radius: $border-radius;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    display: none;

    .nav-link {
      padding-inline: 20px;

      &:hover {
        background: $secondary-50;
      }
    }

    li {
      &:first-child {
        border-radius: $border-radius $border-radius 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }

  &>.nav-link {
    position: relative;
    padding-right: 20px !important;

    &::after {
      $size: 15px;
      content: '';
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="%235f6368"><path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: $size;
      width: $size;
      position: absolute;
      right: 0;
      top: 10px;
    }
  }
}


.quanity-badge {
  $height: 20px;
  height: $height;
  width: $height;
  border-radius: $height;
  background-color: $danger-500;
  border-radius: 15px;
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
