.closed-market-container {
    margin-top: 0;
    margin-bottom: calc($gap / 2);
    background-color: $secondary-50;
    color: $secondary-color;
    display: grid;
    height: max-content;

    .alert {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: calc(($gap / 2) / 2);
        margin-bottom: 0;

        .icon {
            font-size: 3rem;
        }

        .text-container {
            p {
                padding: 0;
                margin: 0;
            }

            .header {
                @include h3-semibold;

                @include media-breakpoint-down(lg) {
                    @include h7-semibold;
                }
            }

            .legend {
                @include h5-regular;

                @include media-breakpoint-down(lg) {
                    @include h8-regular;
                }
            }


        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: calc(($gap / 2) * -1);
    }
}