$cart-preview-img-width: 36px;

.cart-preview {
  width: calc($cart-preview-width - 2px);
  position: absolute;
  right: 0;
  background-color: white;
  border-left: 1px solid $gray-100;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: auto;

  @include media-breakpoint-down(lg) {
    width: 100vw;
    max-height: 80vh;
    overflow-y: auto;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    height: auto;
    z-index: 9999;

    &>div {
      background: #ffffff;
      padding: 1rem;
      display: grid;
      row-gap: 0.75rem;
    }

    &:after {
      content: '';
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.4);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
  }

  hr {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .close-btn {
    display: none;
    position: absolute;
    top: .75rem;
    right: 1rem;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.cart-preview-height {
  top: $header-height;
  height: calc(100vh - $header-height);
}

.cart-preview-height-global-message {
  top: calc($header-height + $global-message-height);
  height: calc(100vh - $header-height - $global-message-height);
}

//on main-container
.cart-preview-showing {
  width: calc(100vw - ($cart-preview-width - 2px));
  display: block;
}

// When is dispayed as individual view:
.cart-preview-page-container {
  background: #ffffff;
  padding: 12px 16px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 10px;

  .offcanvas-header {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.cart-preview-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;


  @include media(small, medium) {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &__tag {
    border-radius: 40px;
    padding: 1px 10px;
    display: grid;
    place-content: center;
    height: 22px;
    @include h8-bold;
  }

  .preview-tag-success {
    background-color: $success-50;
    color: $secondary-800;
  }

  .preview-tag-warning {
    background-color: $primary-50;
    color: $primary-800;
  }

  &__delete {
    @include h7-regular;
    color: $secondary-700;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-align: right;
    cursor: pointer;
  }

  &__delete:hover {
    color: $danger-color;
  }

  &__img {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .title {
    @include h6-bold;
    @include is-head-font;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $secondary-800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .subtitle {
      @include h8-semibold;
      height: 1.4em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $secondary-700;
    }

    .price {
      @include h6-bold;
      margin-top: 3px;
      color: $secondary-800;
      font-variant-numeric: tabular-nums;

      @include media-breakpoint-down(lg) {
        @include h6-semibold;
      }
    }
  }

  &__action {
    grid-area: action;
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 4px;
    align-items: start;

    .cart-delete {
      background: transparent;
      border: 0;
      font-size: 30px;
      height: 24px;
      line-height: 24px;
      display: inline-block;
      margin: 0;
      padding: 0;
    }
  }

  &__no-modify {
    grid-area: action;
    padding-top: $gap-quater;
    align-items: start;

    .mat-mdc-form-field {
      width: 100%;
      height: 30px;
      margin-bottom: 0;
    }
  }
}


.input-plus-minus {
  $height: 24px;
  $radius: 3px;
  display: flex;
  flex-direction: row;
  max-width: 80px;
  height: $height;

  .btn-plusminus {
    height: $height;
    width: $height;
    background-color: $gray-50;
    border: 1px solid $gray-200;
    display: grid;
    place-content: center;
    flex: 0 0 $height;

    &:hover {
      background: $gray-200;
    }

    &:first-child {
      border-right: 0;
      border-radius: $radius 0 0 $radius;
    }

    &:last-child {
      border-left: 0;
      border-radius: 0 $radius $radius 0;
    }
  }

  mat-form-field {
    height: $height;
  }

  .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    border-radius: 0;
    height: $height;
    padding-inline: 2px;

    input {
      text-align: center;
      padding-top: 0;
    }
  }

  .mat-mdc-form-field-flex {
    height: calc($height - 2px)
  }
}

.tooltip-sm .tooltip-inner {
  font-size: 8px;
}
